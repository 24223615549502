<div class="detail" [class.visible]="account">
    <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
      <h2 *ngIf="!account">Inventory Item</h2>
      <h2 *ngIf="account" class="upper">{{account.subtype}}</h2>
    </header>
    <section>
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="account">

        <div class="column-general">
            <div class="field">
                <label>Account Name</label>
                <input type="text" [(ngModel)]="account.name">
            </div>

            <div class="field">
              <label>Contact First Name</label>
              <input type="text" [(ngModel)]="account.firstName" />
            </div>

            <div class="field">
              <label>Contact Last Name</label>
              <input type="text" [(ngModel)]="account.lastName" />
            </div>

            <div class="field">
              <label>Email</label>
              <input type="email" id="email" [(ngModel)]="account.email" />
            </div>
            <div class="field">
              <label>Phone</label>
              <input type="tel" id="telephone" [(ngModel)]="account.telephone" mask="0 (000) 000-0000" />
            </div>
    
            <div class="field">
              <label>Notes</label>
              <textarea [(ngModel)]="account.note[0]"></textarea>
            </div>

            <ng-container *ngIf="account.address?.length > 0">
              <div *ngFor="let address of account.address">
                <div class="field">
                  <label>Address</label>
                  <input type="text" [(ngModel)]="address.streetAddress" />
                </div>
                <div class="field">
                  <label>Address Ext.</label>
                  <input type="text" [(ngModel)]="address.streetAddressExt" />
                </div>
                <div class="field">
                  <label>City</label>
                  <input type="text" [(ngModel)]="address.addressLocality" />
                </div>
                <div class="field">
                  <label>State</label>
                  <!-- <input type="text" [(ngModel)]="address.addressRegion" /> -->
                  <select [(ngModel)]="address.addressRegion">
                    <option *ngFor="let state of states" [value]="state.key">{{state.name}}</option>
                  </select>
                </div>
                <div class="field">
                  <label>Zip</label>
                  <input type="text" [(ngModel)]="address.postalCode" />
                </div>
              </div>
            </ng-container>
            <div *ngIf="!account.address?.length" class="add-address" (click)="addAddress()">
              <div class="btn-add"></div>
              Add Address
            </div>

            <div class="line-field">
              <div class="label-large">Tax Exempt</div>
              <app-toggle (valueChanged)="toggleBoolean('taxExempt')" [checked]="account.taxExempt"></app-toggle>
            </div>



            <div class="error">{{account.error}}</div>
            <div class="buttons" *ngIf="!account.saving">
              <div class="button green" (click)="saveAccount()">Save</div>
              <div class="button red" (click)="deleteAccount()">Delete</div>
            </div>

        </div>


      </div>
    </section>
</div>
