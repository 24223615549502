import { Component, OnInit } from '@angular/core';
import { IoService } from '../../../services/io/io.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-detail-billing-account',
  templateUrl: './detail-billing-account.component.html',
  styleUrls: ['./detail-billing-account.component.scss']
})
export class DetailBillingAccountComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  account;


  states = [
    { name: 'Alabama', key: 'AL' },
    { name: 'Alaska', key: 'AK' },
    { name: 'Arizona', key: 'AZ' },
    { name: 'Arkansas', key: 'AR' },
    { name: 'California', key: 'CA' },
    { name: 'Colorado', key: 'CO' },
    { name: 'Connecticut', key: 'CT' },
    { name: 'Delaware', key: 'DE' },
    { name: 'Florida', key: 'FL' },
    { name: 'Georgia', key: 'GA' },
    { name: 'Hawaii', key: 'HI' },
    { name: 'Idaho', key: 'ID' },
    { name: 'Illinois', key: 'IL' },
    { name: 'Indiana', key: 'IN' },
    { name: 'Iowa', key: 'IA' },
    { name: 'Kansas', key: 'KS' },
    { name: 'Kentucky', key: 'KY' },
    { name: 'Louisiana', key: 'LA' },
    { name: 'Maine', key: 'ME' },
    { name: 'Maryland', key: 'MD' },
    { name: 'Massachusetts', key: 'MA' },
    { name: 'Michigan', key: 'MI' },
    { name: 'Minnesota', key: 'MN' },
    { name: 'Mississippi', key: 'MS' },
    { name: 'Missouri', key: 'MO' },
    { name: 'Montana', key: 'MT' },
    { name: 'Nebraska', key: 'NE' },
    { name: 'Nevada', key: 'NV' },
    { name: 'New Hampshire', key: 'NH' },
    { name: 'New Jersey', key: 'NJ' },
    { name: 'New Mexico', key: 'NM' },
    { name: 'New York', key: 'NY' },
    { name: 'North Carolina', key: 'NC' },
    { name: 'North Dakota', key: 'ND' },
    { name: 'Ohio', key: 'OH' },
    { name: 'Oklahoma', key: 'OK' },
    { name: 'Oregon', key: 'OR' },
    { name: 'Pennsylvania', key: 'PA' },
    { name: 'Rhode Island', key: 'RI' },
    { name: 'South Carolina', key: 'SC' },
    { name: 'South Dakota', key: 'SD' },
    { name: 'Tennessee', key: 'TN' },
    { name: 'Texas', key: 'TX' },
    { name: 'Utah', key: 'UT' },
    { name: 'Vermont', key: 'VT' },
    { name: 'Virginia', key: 'VA' },
    { name: 'Washington', key: 'WA' },
    { name: 'West Virginia', key: 'WV' },
    { name: 'Wisconsin', key: 'WI' },
    { name: 'Wyoming', key: 'WY' }
  ];

  constructor(
    private ioService: IoService,
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadAccount();
      }
    });
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadAccount = async () => {
    console.log('loadAccount');
    this.account = null;

    let account: any = await this.ioService.post('/account/getBillingAccount', {
      accountId: this.documentId
    });

    if (!account.note) {
      account.note = [];
    }

    // Account deficit maximum

    // Credit Maximum

    // Associated Customers

    // Require Customer Selection

    // Get a list of account purchases


    if (account) {
      if (!account.address) {
        account.address = [];
      }

      this.account = account;

      // Get a list of account purchases

    }
  }

  saveAccount = async () => {
    if (!this.account.saving) {
      this.account.saving = true;
      try {
        await this.ioService.post('/account/updateBillingAccount', {
          account: this.account
        });

        this.reportService.loadReport(0);
        this.close();
      } catch (err) {
        this.account.error = err.error.msg;
        this.account.saving = false;
      }
    }

  }

  deleteAccount = async () => {
    if (!this.account.saving) {
      this.account.saving = true;
      try {
        await this.ioService.post('/account/deleteBillingAccount', {
          accountId: this.account._id
        });

        this.reportService.loadReport(0);
        this.close();
      } catch (err) {
        this.account.error = err.error.msg;
        this.account.saving = false;
      }
    }
  }

  addAddress() {
    this.account.address.push({});
  }

  toggleBoolean(toggleTarget) {
    this.account[toggleTarget] = !this.account[toggleTarget];
  }


}
