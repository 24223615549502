import { Component, OnInit, OnDestroy, HostListener  } from '@angular/core';

import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { ClientService } from '../../services/client/client.service';
import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';
import { AuthorizationService } from '../../services/authorization/authorization.service';


import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-virtual-terminal',
  templateUrl: './virtual-terminal.component.html',
  styleUrls: ['./virtual-terminal.component.scss']
})
export class VirtualTerminalComponent implements OnInit {

  activeLocationSet: Subscription;

  oosKey;
  locationId;
  oosUrl;

  transaction;
  responses;

  processing;
  errors;

  customerSearchTimeout;

  stateOptions = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ];

  @HostListener('window:message', ['$event']) onMessage(e) {
     if (e.data == 'tokenized') {
       this.transaction.tokenized = true;
       this.validateTransaction();
     }

   }

  constructor(
    private ioService: IoService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    public sanitizer: DomSanitizer,
    // private messageService: MessageService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.activeLocationSet = this.locationService.activeLocationSetObservable().subscribe(location => {
      if (location._id) {
        this.getLedgers(location._id);
      } else {
        this.transaction = null;
      }
    });

    let location = this.locationService.getActiveLocation();
    if (location && location._id) {
      this.getLedgers(location._id);
    } else {
      this.transaction = null;
    }
  }

  ngOnDestroy() {
    if (this.activeLocationSet) {
      this.activeLocationSet.unsubscribe();
    }
  }

  getLedgers = async (locationId) => {
    let location = this.locationService.getLocation(locationId);

    this.responses = null;

    this.transaction = {
      locationId,
      locationName: location.name
    };

    // Get location ledgers
    let ledgers: any = await this.ioService.post('/ledger/getLedgers', {
      locationId
    });

    // Lookup transaction permissions for each ledger
    let transactionActions = ['add', 'subtract', 'check', 'addAny', 'history'];
    for (let ledger of ledgers) {
      let actions = [];
      for (let action of transactionActions) {
        if (this.authorizationService.checkPermission('transaction.'+ledger.type+'.'+action, locationId) && ledger.actions && ledger.actions[action]) {
          ledger.actions[action].key = action;
          actions.push(ledger.actions[action]);
        }
      }
      ledger.actions = actions
    }

    this.transaction.ledgers = ledgers;
    // console.log('transaction: ', this.transaction);
  }

  setTransactionLedger = async (ledger) => {
    this.transaction.ledger = ledger;
    this.transaction.type = ledger.type;

    if (ledger.account.tokenizerUrl) {
      let oos: any = await this.ioService.post('/transaction/credit/token', {
        locationId: this.transaction.locationId
      });

      this.transaction.oosUrl = this.sanitizer.bypassSecurityTrustResourceUrl(String(oos.oosUrl));
      this.transaction.key = oos.key;

      // this.transaction.oosUrl = this.getOosUrl();
    }

    console.log('transaction: ', this.transaction);
  }

  // getOosUrl() {
  //   let iframeUrl = this.transaction.ledger.account.tokenizerUrl+'form?clientId='+this.clientService.getClientKey()+'&locationId='+this.transaction.locationId+'&key='+this.transaction.key;
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  // }

  setTransactionAction(action) {
    this.transaction.action = action;
  }

  countDecimals(value) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
  }

  amountChanged() {
    this.transaction.valid = false;

    console.log('amount: ', this.transaction.amount);

    // If decimal places are greater than allowed, round up or down
    if (this.transaction.amount && this.countDecimals(this.transaction.amount) > this.transaction.ledger.amount.precision) {
      switch (this.transaction.action.key) {
        case 'add':
          // Round Down
          this.transaction.amount = Math.floor(this.transaction.amount*this.transaction.ledger.amount.divisor)/this.transaction.ledger.amount.divisor;
          break;

        case 'subtract':
          // Round Up
          this.transaction.amount = Math.ceil(this.transaction.amount*this.transaction.ledger.amount.divisor)/this.transaction.ledger.amount.divisor;
          break;
      }
    }

    this.transaction.amountDisplay = Number(this.transaction.amount).toFixed(this.transaction.ledger.amount.precision);
    if (this.transaction.amountDisplay == 'NaN') {
      this.transaction.amountDisplay = '0.00';
    }

    this.validateTransaction();
  }

  keyEntered() {
    console.log('keyEntered: ', this.transaction.key);

    let rawKey = this.transaction.key;

    if (rawKey.indexOf("=") == -1) {
      // ;0705200000481654?
      rawKey = rawKey.substring(
        rawKey.lastIndexOf(";") + 1,
        rawKey.lastIndexOf("?")
      ).replace(/\D/g,'');
    } else {
      rawKey = rawKey.substring(
        rawKey.lastIndexOf(";") + 1,
        rawKey.lastIndexOf("=")
      ).replace(/\D/g,'');
    }

    console.log('rawKey: ', rawKey);
    if (this.transaction.key.trim().slice(-1) == '?') {
      this.transaction.key = rawKey;
      this.transact();
    }
  }

  keyChanged() {


  }

  validateTransaction() {
    console.log('validateTransaction');

    // Validate tokenized
    let valid = true;

    let testAmount = this.transaction.amount;
    if (this.transaction.action.key == 'subtract') {
      testAmount *= -1;
    }

    if (testAmount || testAmount === 0) {
      if (testAmount < this.transaction.action.min/this.transaction.ledger.amount.divisor) {
        console.log('Amount is below the minimum');
        valid = false;
      }
      if (testAmount > this.transaction.action.max/this.transaction.ledger.amount.divisor) {
        console.log('Amount exceeds the maximum');
        valid = false;
      }
    } else {
      console.log('Amount is required.');
      valid = false;
    }

    if (this.transaction.ledger.account.tokenizerUrl && !this.transaction.tokenized) {
      console.log('Waiting for tokenizaiton');
      valid = false;
    }

    if (this.transaction.action.customerIdRequired && !this.transaction.customer) {
      console.log('Customer is required.');
//      valid = false;
    }

    this.transaction.valid = valid;
  }


  transact = async () => {
    console.log('TRANSACT!');
    if (!this.transaction.processing && (this.transaction.valid || this.transaction.action.key == 'check')) {
      this.transaction.processing = true;
      this.transaction.error = null;
      this.transaction.response = null;

      let transaction = {
        locationId: this.transaction.locationId,
        amount: this.transaction.amount,
        key: null,
        note: null,
        subtype: null,
        accountSubtype: null,
        ledgerId: this.transaction.ledger._id,
        // customer: {
        //   address: {
        //     streetAddress: '123 Example Street',
        //     postalCode: '85284'
        //   }
        // }
      };

      if (this.transaction.key) {
        transaction.key = this.transaction.key;
      }

      if (this.transaction.subtype) {
        transaction.accountSubtype = this.transaction.subtype;
        transaction.subtype = this.transaction.subtype;
      }

      if (this.transaction.note) {
        transaction.note = this.transaction.note;
      }

      if (!this.responses) {
        this.responses = [];
      }

      try {
        let response = await this.ioService.post('/transaction/'+this.transaction.ledger.type+'/'+this.transaction.action.key, transaction);
        this.responses.unshift(response);
        if (!this.transaction.successCount) {
          this.transaction.successCount = 0;
        }
        this.transaction.successCount++;
      }
      catch(transactionError) {
        // this.transaction.error = transactionError.error;
        let error = transactionError.error;
        this.responses.unshift(error);
      }

      this.transaction.processing = false;
    }
  }


  getHistory = async () => {
    if (!this.transaction.processing) {
      this.transaction.processing = true;
      this.transaction.error = null;
      this.transaction.response = null;

      try {
        let response:any = await this.ioService.post('/report/loadRecords', {
          key: 'account-history',
          utcOffset: new Date().getTimezoneOffset(),
          clientConfig: {
            clientFilters: [
              {
                name: 'Account Key',
                path: 'key',
                type: 'string',
                value: this.transaction.key,
              },
              {
                name: 'Account Type',
                path: 'type',
                type: 'string',
                value: this.transaction.ledger.type,
              }
            ],
          },
        });

        this.responses = response.records;
      }
      catch(historyError) {
        let error = historyError.error;
        this.responses = [error];
      }

      this.transaction.processing = false;
    }
  }


  newErrors() {
    return {
      csc: null,
      postalCode: null,
      streetAddress: null,
      amount: null
    };
  }






  clearCustomer() {
    // this.transaction.customer
  }

  loadCustomer(customer) {
    console.log('found customer: ', customer);
    this.transaction.customer = customer;
    if (customer.address) {
      this.transaction.billing = customer.address;
    }
  }

  cancelClick() {

  }

  chargeClick() {
    if (!this.processing) {
      this.processing = true;

      this.errors = {};

      if (!this.transaction.amount || this.transaction.amount == 0) {
        this.errors.amount = 'Amount is required';
      }

      if (!this.transaction.payment.csc || this.transaction.payment.csc.length < 3) {
        this.errors.csc = 'CVV is required';
      }

      // if (!this.transaction.billing.streetAddress || this.transaction.billing.streetAddress.length < 3) {
      //   this.errors.streetAddress = 'Street Address is required';
      // }

      if (!this.transaction.billing.postalCode || this.transaction.billing.postalCode.length < 5) {
        this.errors.postalCode = 'Zip code is required';
      }

      if (Object.entries(this.errors).length === 0 && this.errors.constructor === Object) {
        // There are no validation errors
        this.creditSale();
      } else {
        this.processing = false;
      }
    }
  }

  creditSale() {

    // Copy customer name to billing name
    if (this.transaction.customer.firstName) {
      this.transaction.billing.firstName = this.transaction.customer.firstName;
      this.transaction.billing.lastName = this.transaction.customer.lastName;
    }

    this.ioService.post('/transaction/credit/subtract', this.transaction).then((result: any) => {
      console.log(result);
      result.success = true;
      this.transaction.response = result;
      this.processing = false;

    }).catch((err: any) => {
      console.log(err);
      err.success = false;
      this.transaction.response = err.error;
      this.processing = false;

    });
  }

}
